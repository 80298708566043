<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="readOnly"
                :formItems="popForms">
                </m-dialog-content>
            </a-modal>
        <m-form
            :formItems="formItems"
            @add="add" 
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @modify="modify($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { addNoticeInfo, isValid, noticeInfoDetail,noticeInfoModify,noticeList } from '../../utils/api'
import { assginDataToArray, formatTime, geneIndex, getValueFromObjArr } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '修改', eventName: 'modify'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index', width: 80},
                //  {title: '中奖人姓名', dataIndex: 'name', key: 'name'},
                //  {title: '中奖金额（元）', dataIndex: 'prize', key: 'prize'},
                //  {title: '购彩金额（元）', dataIndex: 'buyMoney', key: 'buyMoney'},
                 {title: '轮播内容', dataIndex: 'content', key: 'content'},
                 {title: '状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                {type: 'selector', label: '状态', key: 'value', options: [{label: '不可用', value: 0}, {label: '展示中', value: 1}, {label: '已删除', value: 2}], value: undefined},
                {type: 'button', eventName: 'loadData', label: '搜索'},
                {type: 'button', eventName: 'add', label: '添加'},
             ],
             readOnly: true,
             popForms: [
                //  {label: '姓名', prop: 'name', value: '', type: 'input'},
                 {label: '公告ID', prop: 'id', hidden: false, value: '', type: 'input'},
                //  {label: '中奖人姓名', prop: 'name', hidden: false, value: '', type: 'input'},
                //  {label: '中奖金额(元)', prop: 'prize', hidden: false, value: '', type: 'input'},
                //  {label: '购彩金额(元)', prop: 'buyMoney', hidden: false, value: '', type: 'input'},
                 {label: '轮播内容', prop: 'content', hidden: false, value: '', type: 'input'},
                 {label: '创建时间', prop: 'createTime', hidden: false, value: '', type: 'input'},
                 {label: '最近修改', prop: 'updateTime', hidden: false, value: '', type: 'input'},
                 {label: '状态', hidden: false, type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '不可用', value: 0}, {labelName: '显示中', value: 1}, {labelName: '已删除', value: 2}]},
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             id: '',
        })

        const statusList = [
            [0, '不可用'],
            [1, '显示中'],
            [2, '已删除']
        ]

        const hiddenProps = ['id', 'createTime', 'updateTime']
        const addHiddenProps = ['id', 'createTime', 'updateTime', 'status']
       
        async function  loadData(pageNum = null) {
            try {
                state.currentPage = pageNum || state.currentPage
                state.loading = true
                const item = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    status: item[0].value,
                }
                const result = await noticeList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.tableList.forEach(ele => {
                        ele.statusText = getValueFromObjArr(statusList, ele.status)
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
       
        async function detail(event) {
            try {
                state.readOnly = true
                state.id = event.id
                state.fundDetailPageNum = 1
                const result = await noticeInfoDetail(event.id)
                if (isValid(result)) {
                    const ele = result.data
                    ele.createTime = formatTime(ele.createTime)
                    ele.statusText = getValueFromObjArr(statusList, ele.status)
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    console.log(state.popForms)
                    state.showDialog = true
                }
                state.popForms.forEach(ele => {
                    ele.hidden = false
                })
            } catch(e) {
                console.error(e)
            }
        }

        

        async function addNew() {
            try {
                const items = state.popForms
                const paramsArr = ['content', 'status']
                const params = {}
                paramsArr.forEach(ele => {
                    params[ele] = items.filter(item => item.prop == ele)?.[0]?.value
                })
                const result = await addNoticeInfo(params)
                if (result.code == 0) {
                    message.success('添加成功')
                }
            } catch(e) {
                console.error(e)
            }
        }

        async function editNotice() {
            try {
                const items = state.popForms
                const paramsArr = ['id', 'content', 'status']
                const params = {}
                paramsArr.forEach(ele => {
                    params[ele] = items.filter(item => item.prop == ele)?.[0]?.value
                })
                const result = await noticeInfoModify(params)
                if (result.code == 0) {
                    message.success('修改成功')
                }
            } catch(e) {
                console.error(e)
            }
        }
       
        
        async function closeDialog() {
            if (!state.readOnly) {
                if (state.id) {
                    await editNotice()
                } else {
                    await addNew()
                }
            }
            loadData()
            state.showDialog = false
        }

        function add() {
            state.popForms.forEach(ele => ele.value = '')
            state.popForms.forEach(ele => {
                    if (addHiddenProps.indexOf(ele.prop) > -1) {
                        ele.hidden = true
                    }
            })
            state.showDialog = true 
            state.readOnly = false
            state.id = ''
        }

        function modify(event) {
            state.readOnly = false
            state.isNew = false
            state.id = event.id
            state.popForms.forEach(ele => {
                ele.value = event[ele.prop]
            })
            state.popForms.forEach(ele => ele.hidden = false)
            state.popForms.forEach(ele => {
                    if (hiddenProps.indexOf(ele.prop) > -1) {
                        ele.hidden = true
                    }
                })
            state.showDialog = true
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            modify,
            add
        }
    },
}
</script>
<style lang='scss' scoped>
.fund_load_info {
    display: flex;
    justify-content: space-between;
    text {
        font-weight: 700;
    }
    button {
        color: blue;
        border: solid blue 1px;
        padding: 0px 15px;
        border-radius: 15px;
    }
}
.fund_detail {
    height: 220px;
    overflow: auto;
    position: relative;
    table {
        width: 100% !important;
    }
    td {
        width: 20%;
    }
}
</style>